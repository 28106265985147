<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">{{title}}</p>
            <p class="title_tip3">TRACE EVIDENCE IDENTIFICATION</p>
        </div>
        <div class="center_content bold">
            《声像资料司法鉴定执业分类规定》司法部司规［2020］5号
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            图像鉴定是指鉴定人运用物理学、信息科学与技术、同一认定理论等原理、方法和专门知识，对检材图像（录像/视频、照片/图片）的真实性、同一性、相似性及所反映的内容等专门性问题进行检验、分析、鉴别和判断并提供鉴定意见的活动。
        </div>
        <div style="margin:2rem 0" class="flex wrap">
          <div class="col3 flex center" style="margin-bottom:2rem;" v-for="(item,index) in data" :key="index">
            <div class="col5 hvr-forward">
              <div style="margin-bottom:1rem;">
                <img :src="require('../../assets/range/audio_video/'+item[0])">
              </div>
              <p> 
                {{(index-0+1)+'.'+item[1]}}
              </p>
            </div>
          </div>
        </div>
        <div class="col8" style="margin:0 auto">
          <img style="width:100%" :src="require('../../assets/range/audio_video/2-1.jpg')">
        </div>
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
import mixin from "./mixin.js"
export default {
  mixins:[mixin],
  data(){
    return{
      data:[
        ['1-1.jpg','图像处理'],
        ['1-2.jpg','图像真实性鉴'],
        ['1-3.jpg','图像同一性鉴定'],
        ['1-4.jpg','图像内容分析'],
        ['1-5.jpg','图像作品相似性鉴定'],
        ['1-6.jpg','特种照相检验'],
    
      ]
    }
  }
}
</script>
